import React from 'react'
import './styles.scss'
import Layout from "../components/layouts"

import Content from '../containers/content/'
import ServicesLayout from '../containers/services-layout/'

const IndexPage = () => (
  <Layout>
    <Content>
      <ServicesLayout/>
    </Content>
  </Layout>
)

export default IndexPage
