import React from 'react'
import Link from 'gatsby-link'
import FontAwesome from 'react-fontawesome';
import './styles.scss'

const ServiceCard = (props) =>
  <div className='serviceCard'>
    <img src={props.img} alt="service"/>
    <h2>{props.title}</h2>
    <p>{props.text}</p>
    <Link className='quoteBtn' to='/contact/'> Request Quote <FontAwesome className='quoteIcon' name='angle-double-right' size='1x' /></Link>
  </div>

export default ServiceCard
