import React, { Component } from 'react';
import Prismic from 'prismic-javascript';
import './styles.scss'

import PageTitle from './../../components/page-title/';
import ServiceCard from '../../components/service-card/';

class ServiceLayout extends Component {
  state = {
    doc: null,
  }

  componentWillMount() {
    const apiEndpoint = 'https://alllcare.prismic.io/api/v2';

    Prismic.api(apiEndpoint).then(api => {
      api.query(
        Prismic.Predicates.at('document.type', 'services')
      ).then(response => {
        if (response) {
          this.setState({ doc: response.results[0] });
        }
      });
    });
  }

  render() {
    if (this.state.doc) {
      const document = this.state.doc.data
      return (
        <div>
          <PageTitle
            title={document.title[0].text}
            text={document.page_description}
          />
          <div className='serviceCardContainer'>
            {document.services.map((service, i )=> {
              return (
                <ServiceCard
                  key={i}
                  img={service.service_image.url}
                  title={service.title[0].text}
                  text={service.description}
                />
              )
            })}
          </div>
        </div>
      );
    }
    return (
      <div className="spinner">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
      </div>
    );
  }
}

export default ServiceLayout;
